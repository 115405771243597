// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hQSVEgcs{padding:0 0 40px}@media(min-width:667.98px){.hQSVEgcs{padding:0 0 100px}}.aekaOpcn{display:flex}@media(min-width:667.98px){.aekaOpcn{display:none}}.nnby\\+Fcf{display:none}@media(min-width:667.98px){.nnby\\+Fcf{display:flex;padding:0 0 60px;position:relative}}.dkDIb2MU{display:none}@media(min-width:667.98px){.dkDIb2MU{bottom:0;display:block;left:0;opacity:.5;position:absolute;right:0;top:0}}.ZIjC\\+leB{flex-basis:90vw}@media(min-width:667.98px){.ZIjC\\+leB{flex-basis:50vw;padding:0 32px 0 0;transition:transform .35s}}@media(min-width:667.98px)and (hover:hover){.ZIjC\\+leB:hover{transform:translateY(.5rem)}}@media(min-width:667.98px)and (hover:none){.ZIjC\\+leB:active{transform:translateY(.5rem)}}@media(min-width:947.98px){.ZIjC\\+leB{flex-basis:26vw}}@media(min-width:1229.98px){.ZIjC\\+leB{padding:0 90px 0 0}}.fvuAnjhn{color:var(--color-text);font-size:14px;opacity:.5;padding:0 0 16px}@media(min-width:667.98px){.fvuAnjhn{font-size:24px}}.C-NuMAhv{flex-grow:1;font-size:24px;font-weight:400;margin:0;padding:0 0 16px}@media(min-width:947.98px){.C-NuMAhv{font-size:30px;padding:0 0 24px}}._3ldAz89T{margin:0 0 40px}@media(min-width:947.98px){._1\\+e2CqU5{flex-basis:48vw}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "hQSVEgcs",
	"swiper": "aekaOpcn",
	"news": "nnby+Fcf",
	"newsBackgrpound": "dkDIb2MU",
	"item": "ZIjC+leB",
	"itemTime": "fvuAnjhn",
	"itemTitle": "C-NuMAhv",
	"itemImage": "_3ldAz89T",
	"itemFirst": "_1+e2CqU5"
};
module.exports = ___CSS_LOADER_EXPORT___;
