
export default {
  data: () => ({
    news: [],
    swiperOpts: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
  }),

  async fetch () {
    await this.fetchNews()
  },

  methods: {
    async fetchNews () {
      const [res, err] = await this.$api.news.getNews()
      if (err) console.error(err.response.data)
      else this.news = this.$api.helpers.getList(res)
    },
  },
}
